export const types = {
  SAMPLE_TYPE: "SAMPLE_TYPE",
  LOGIN: "LOGIN",
  LOGOUT: "LOGOUT",
  AUTHENTICATED: "AUTHENTICATED",
  SHOW_AUTH_MESSAGE: "SHOW_AUTH_MESSAGE",
  SIGNOUT: "SIGNOUT",
  SIGNOUT_SUCCESS: "SIGNOUT_SUCCESS",
  SHOW_LOADING: "SHOW_LOADING",
  SHOW_AUTH_LOADING: "SHOW_AUTH_LOADING",
  AUTH_TOKEN: "auth_token",
  BUYER_SUPPLIER_DATA: "BUYER_SUPPLIER_DATA",
  ADD_BUYER: "ADD_BUYER",
  EDIT_USER: "EDIT_USER",
  TOGGLE_NAV: "TOGGLE_NAV",
  HEADER_HEADING: "HEADER_HEADING",
  INBOX_TYPE: "INBOX_TYPE",
  PAGE_NO: "PAGE_NO",
  LOGO: "LOGO",
  TOGGLE_CHANGE: "TOGGLE_CHANGE",
  CHANGE_USER_STATUS: "CHANGE_USER_STATUS",
  LAST_LOGIN_CHANGE: "LAST_LOGIN_CHANGE",
  UNASSIGNED_TASKS: "UNASSIGNED_TASKS",
  MIRO_POSTING_FAILED: "MIRO_POSTING_FAILED",
  DUPLICATE_MIRO: "DUPLICATE_MIRO",
  ASSIGNED_TASKS: "ASSIGNED_TASKS",
  INVOICE_HEADER_ITEM: "INVOICE_HEADER_ITEM",
  SET_NAVIGATION: "SET_NAVIGATION",
  ASSIGN_SELF_SUCCESS: "ASSIGN_SELF_SUCCESS",
  ASSIGN_SELF_LOADING: "ASSIGN_SELF_LOADING",
  ASSIGN_SELF_ERROR: "ASSIGN_SELF_ERROR",
  GET_PO_TABLE: "GET_PO_TABLE",
  GET_GRN_TABLE: "GET_GRN_TABLE",
  GET_ActionHistory_TABLE: "GET_ActionHistory_TABLE",
  MODAL_ACTION: "MODAL_ACTION",
  ADD_NEW_ROLE: "ADD_NEW_ROLE",
  ADD_USER_ROLE: "ADD_USER_ROLE",
  GET_ROLE_LIST: "GET_ROLE_LIST",
  GET_BUYER_LIST: "GET_BUYER_LIST",
  APPROVE_FORWARD_REJECT: "APPROVE_FORWARD_REJECT",
  REJECT_REASON: "REJECT_REASON",
  PO_CHANGE_TASK: "PO_CHANGE_TASK",
  TAX_LIST_OC_SEARCH: "TAX_LIST_OC_SEARCH",
  RE_ASSIGN_TASK: "RE_ASSIGN_TASK",
  BUYEAR_SEARCH_DATA: "BUYEAR_SEARCH_DATA",
  GEN_SRN_VALUE: "GEN_SRN_VALUE",
  TASK_VIEW_TYPE: "TASK_VIEW_TYPE",
  NEXT_PREV_TASK: "NEXT_PREV_TASK",
  PROFILE_EDIT__DATA: "PROFILE_EDIT__DATA",
  USER_DATA: "USER_DATA",
  READ_ONLY_INV_DETAILS: "READ_ONLY_INV_DETAILS",
  RESET_BEFORE_ENTER: "RESET_BEFORE_ENTER",
  ROW_DETAILS: "ROW_DETAILS",
  ASSIGNED_CARD_DETAILS: "ASSIGNED_CARD_DETAILS",
  UNASSIGNED_CARD_DETAILS: "UNASSIGNED_CARD_DETAILS",
  ADVANCED_SEARCH: "ADVANCED_SEARCH",
  ADVANCED_SEARCH_TL: "ADVANCED_SEARCH_TL",
  ADVANCED_SEARCH_TOTAL: "ADVANCED_SEARCH_TOTAL",
  ADVANCED_SEARCH_TOTAL_TL: "ADVANCED_SEARCH_TOTAL_TL",
  DASHBOARD_COUNTS_ACCOUNTS_PAYABLE: "DASHBOARD_COUNTS_ACCOUNTS_PAYABLE",
  DASHBOARD_COUNTS_PO_MATERIAL: "DASHBOARD_COUNTS_PO_MATERIAL",
  GET_TASK_LIFE_CYCLE: "GET_TASK_LIFE_CYCLE",
  SET_POSTING_DATE_URL: "SET_POSTING_DATE_URL",
  SET_TOTAL_LOCATION: "SET_TOTAL_LOCATION",
  SET_STATE_TASK_RECORDS: "SET_STATE_TASK_RECORDS",
  SET_UPLOADED_FILES_DATA: "SET_UPLOADED_FILES_DATA",
  TASK_LIST_FILTER_STATE: "TASK_LIST_FILTER_STATE",
  MY_TASK_LIST_FILTER_STATE: "MY_TASK_LIST_FILTER_STATE",
  MIRO_TASK_LIST_FILTER_STATE: "MIRO_TASK_LIST_FILTER_STATE",
  DUP_TASK_LIST_FILTER_STATE: "DUP_TASK_LIST_FILTER_STATE",
  SET_UPLOADED_FILES_DESCRIPTION: "SET_UPLOADED_FILES_DESCRIPTION",
  BARCODE_FAILURE_TASKS: "BARCODE_FAILURE_TASKS",
  BARCODE_TASK_LIST_FILTER_STATE: "BARCODE_TASK_LIST_FILTER_STATE",
  TASK_TYPE_NAME: "TASK_TYPE_NAME",
  URL_ON_ASSIGN: "URL_ON_ASSIGN",
  INBOX_TYPE: "INBOX_TYPE",
  DynamicTableValidation: "DynamicTableValidation",
  ResetDynamicTableValidation: "ResetDynamicTableValidation",
  ResetCompleteDynamicTableValidation:"ResetCompleteDynamicTableValidation"
}
